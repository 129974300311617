<template>
  <v-container fluid class="py-16 mx-auto">
    <v-img
      :src="require('@/assets/logo.svg')"
      class="mt-0 mb-5"
      contain
      height="38"
    >
    </v-img>
    <p class="blue-grey--text text-center mb-12">
      Repository of Vocabularies and Schemas. β1.0
    </p>
    <v-card class="mx-auto" max-width="550">
      <v-card-title>Sign-Up</v-card-title>
      <v-card-text>
        <div class="d-flex align-center mb-3">
          <v-avatar color="indigo" size="48" class="mr-2">
            <v-icon dark>mdi-account-circle</v-icon>
          </v-avatar>
          <!-- <v-btn color="#8F5F98" text>変更</v-btn> -->
        </div>

        <v-form ref="form">
          <v-text-field
            label="Display Name"
            placeholder="Taro Yamada"
            v-model="account.name"
            hide-details="auto"
            :rules="[rules.required]"
          ></v-text-field>

          <v-text-field
            label="E-mail"
            v-model="account.mail"
            placeholder="xxx@xxx.xxx"
            hide-details="auto"
            :rules="[rules.required, rules.email]"
          ></v-text-field>

          <v-text-field
            v-model="account.pass"
            label="PASSWORD"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            hide-details="auto"
            @click:append="show1 = !show1"
            :rules="[rules.required, rules.password]"
          ></v-text-field>
          <p class="grey--text caption">
            {{ getWords("signup_pass") }}
          </p>
        </v-form>
        <p v-if="errmsg !== ''" class="red--text caption mt-4 ml-2">
          {{ errmsg }}
        </p>
        <div class="d-flex justify-center mt-16">
          <v-btn
            large
            outlined
            color="#5C5C5C"
            :loading="isProcessing"
            :disabled="isProcessing"
            @click="handleSignUp"
          >
            Sign Up
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { auth, updateUser } from "../../plugins/firebase";
import generateApiKey from "generate-api-key";
import words from "../../assets/localewords.json";

export default {
  name: "sing_up",
  data: () => ({
    account: {
      pass: "",
      name: "",
      mail: "",
    },
    isProcessing: false,
    show1: false,
    rules: {
      required: (v) => (v && !!v) || "Required.",
      email: (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Invalid email",
      password: (v) => /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,100}$/.test(v) || "Requires at least 8 letters it contains at least one 'Uppercase', one 'Lowercase', one 'Number'.",
    },
    errmsg: "",
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.login;
    },
    getLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    isLoggedIn: function (to) {
      if (to === true && this.isProcessing === true) {
        this.isProcessing = false;
      }
    },
  },
  methods: {
    handleSignUp: async function () {
      if (this.$refs.form.validate()) {
        this.isProcessing = true;
        this.errmsg = "";
        try {
          if (this.isLoggedIn) {
            await auth.signOut();
          }
          const userCredentical = await auth.createUserWithEmailAndPassword(this.account.mail, this.account.pass);
          const apikey = generateApiKey({
            method: "string",
            pool: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            length: 64,
          });
          const updateData = {
            displayName: this.account.name,
            apiKey: apikey,
            uid: userCredentical.user.uid,
          };
          await updateUser(updateData);
          // const updated = await result.user.updateProfile({ displayName:this.account.name });
          // console.log(updated);
          await auth.signOut();
          this.isProcessing = false;
          this.$router.push({ name: "Login" }).catch((err) => console.log(err));
        } catch (err) {
          console.log(err);
          this.errmsg = err.message;
          this.isProcessing = false;
        }
      }
    },
    getWords(key) {
      let lang = this.getLanguage;
      if (lang !== "en" && lang !== "ja") {
        lang = "en";
      }
      const word = words[key] ? words[key][lang] : "";
      return word;
    },
  },
};
</script>

<style scoped></style>
